import { Injectable } from '@angular/core'
import { Observable, of, Subscription } from 'rxjs'
import { getIdsUndefined } from 'src/app/pipes/check-all-horraire/check-all-horraire.pipe'
import { User } from 'src/app/shared/data/User'
import { ApiService } from '../api/api.service'
import { AuthenticationStateService } from '../authentication-state/authentication-state.service'
import { UserService } from '../user/user.service'

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  total?: Observable<number>
  document?: Observable<number>
  besoin?: Observable<number>
  contact?: Observable<number>
  profil?: Observable<number>
  recurrent = 0
  ponctuel = 0

  getNotificationsOfUserSub: Subscription
  notifications = {
    lus: [],
    non_lus: [],
  }
  notifTypes = []
  nbNotifsNonLues = 0
  loaderNotifs: boolean = false

  constructor(
    private authenticationStateService: AuthenticationStateService,
    private api: ApiService
  ) {}

  setDocument() {}

  checkDate(object): boolean {
    const then = new Date(object.date_fin)

    const now = new Date()

    if (then < now) {
      return false
    } else {
      return true
    }
  }

  setBesoin(besoins: any): void {
    let num = 0
    console.log('Besoin', besoins)
    this.recurrent = 0
    besoins.recurrent.forEach(besoin => {
      this.recurrent += besoin.notification.length
    })

    this.ponctuel = 0
    besoins.ponctuel.forEach(besoin => {
      if (this.checkDate(besoin)) {
        this.ponctuel += besoin.notification.length
      }
    })

    num = this.recurrent + this.ponctuel

    this.besoin = of(num)
  }

  async setTotal(): Promise<void> {
    let total = 0
    await this.besoin.subscribe(n => (total += n))
    this.total = of(total)
    console.log('total', total)
  }

  async getNotificationsOfUser() {
    this.nbNotifsNonLues = 0
    this.notifications.lus = []
    this.notifications.non_lus = []
    this.loaderNotifs = true
    const userId = (this.authenticationStateService.userValue as User)?.id
    this.getNotificationsOfUserSub = this.api
      .getNotificationsOfUser(userId)
      .subscribe(async resp => {
        if (resp && resp?.success) {
          this.notifTypes = resp.notifTypes
          resp.datas.forEach(notif => {
            if (!notif.is_read) {
              this.nbNotifsNonLues++
              this.notifications.non_lus.push(notif)
            } else {
              this.notifications.lus.push(notif)
            }
          })
        } else {
        }
        this.loaderNotifs = false
      })
    console.log('nbNotifsNonLues', this.nbNotifsNonLues)
  }
}

export interface Notification {
  icon: string
  titre: string
  time: number
  url?: string
}
