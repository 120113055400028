export const environment = {
  tarifsPrestaTtc: {
    recurent: 24,
    recurent_we: 30,
    ponctuel: 30,
    ponctuel_we: 38,
    recurent_gouvernante: 30,
    recurent_gouvernante_we: 50,
    ponctuel_gouvernante: 38,
    ponctuel_gouvernante_we: 70,
  },

  typeTransport: [
    {
      id: 2,
      ximiTransportTypeId: '-1',
      googleTravelMode: google.maps.TravelMode.WALKING,
      label: 'A pied',
      icon: 'marche.png',
    },
    {
      id: 5,
      ximiTransportTypeId: '0',
      googleTravelMode: google.maps.TravelMode.TRANSIT,
      label: 'Transports en commun',
      icon: 'bus.png',
    },
    {
      id: 3,
      ximiTransportTypeId: '1',
      googleTravelMode: google.maps.TravelMode.DRIVING,
      label: 'Voiture',
      icon: 'voiture.png',
    },
    {
      id: 1,
      ximiTransportTypeId: '3',
      googleTravelMode: google.maps.TravelMode.BICYCLING,
      label: 'Vélo',
      icon: 'velo.png',
    },
  ],

  production: false,
  appVersion: '0.0.23',
  GOOGLE_KEY_API: 'AIzaSyCPYUCP5IJKGRXu53J1rLIX3EivrxhxXOA',
  stripeKey: 'pk_live_TuMYsfECP1qwm1LrOKEzkkG700bn6iyAst',
  urlFront: 'https://wanteez.eu/',
  urlApi: 'https://api.wanteez.eu',
  urlSocket: 'https://websocket.wanteez.eu',

  urlApiImageProd: 'https://imageapi.wanteez.eu',
  urlSocketImageProd: 'https://websocket.imageprod.wanteez.eu',

  encryptSecretKey: 'KUoYdXvTvlrG8MxnyBQ4t3KfcBDWTALX',
  phoneWanteez: '0474110532',
  mailServicePaie: 'paie@wanteez.fr',
  mailServiceRh: 'rh@wanteez.fr',
  mailServiceCommercial: 'contact@wanteez.fr',
  mailServiceComptable: 'comptabilite@wanteez.fr',

  showLogs: false,
  perimetreRechercheDefault: 20,
  metersArrivedAtClient: 50,
  metersLeftFromClient: 50,

  fbPixelId: '545646394149907',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
