import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { Router, RouteReuseStrategy } from '@angular/router'

import { IonicModule, IonicRouteStrategy } from '@ionic/angular'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { CommonModule } from '@angular/common'
import { FileChooser } from '@ionic-native/file-chooser/ngx'
import { IOSFilePicker } from '@ionic-native/file-picker/ngx'
import { Camera } from '@ionic-native/camera/ngx'
import { MediaCapture } from '@ionic-native/media-capture/ngx'
import { File } from '@ionic-native/file/ngx'
import { PDFGenerator } from '@ionic-native/pdf-generator/ngx'

import { FullCalendarModule } from '@fullcalendar/angular'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import { AgmCoreModule } from '@agm/core'
import { AgmDirectionModule } from 'agm-direction'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { UserService } from './services/user/user.service'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx'
import { ApiService } from './services/api/api.service'
import { AppService } from './services/app.service'
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx'
import { BackgroundGeolocation } from '@awesome-cordova-plugins/background-geolocation/ngx'
import { HttpError } from './interceptors/httperror'
import { AuthHeaderInterceptor } from './interceptors/auth-header/auth-header'
import { StatusBar } from '@ionic-native/status-bar/ngx'
import { registerLocaleData } from '@angular/common'
import localeFr from '@angular/common/locales/fr'
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from '../environments/environment'
import { AuthGuard } from './services/authguard/AuthGuard.service'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core'

import { PixelModule } from 'ngx-pixel'
import { Facebook } from '@ionic-native/facebook/ngx'
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx'
import * as Sentry from '@sentry/angular-ivy'

registerLocaleData(localeFr, 'fr')

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    CommonModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCPYUCP5IJKGRXu53J1rLIX3EivrxhxXOA',
    }),
    AgmDirectionModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    FileChooser,
    IOSFilePicker,
    StatusBar,
    Camera,
    MediaCapture,
    File,
    PDFGenerator,
    UserService,
    InAppBrowser,
    AuthGuard,
    ApiService,
    AppService,
    FCM,
    Facebook,
    Deeplinks,
    BackgroundGeolocation,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpError,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
