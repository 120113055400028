import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AuthenticationStateService } from "../authentication-state/authentication-state.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(public authenticationService: AuthenticationStateService) {}

  canActivate(): boolean {
    return this.authenticationService.check();
  }
}
