import { catchError, finalize, tap } from 'rxjs/internal/operators'
import { Injectable, Injector } from '@angular/core'
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { Router } from '@angular/router'
import { TokenAuthService } from '../services/token-auth/token-auth.service'
import { AuthenticationStateService } from '../services/authentication-state/authentication-state.service'
import { JwtService } from '../services/jwt/jwt.service'

@Injectable()
export class HttpError implements HttpInterceptor {
  constructor(
    public router: Router,
    private tokenAuthService: TokenAuthService,
    public authenticationStateService: AuthenticationStateService,
    private jwtService: JwtService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(data => {}),
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          console.error('An error occurred:', error.error.message)
        } else {
          console.error(
            `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`
          )
          console.log('request', request)
          if (
            [401].includes(error.status) &&
            this.authenticationStateService.userValue &&
            this.tokenAuthService.tokenExpired(
              this.tokenAuthService.getJwtToken() as string
            ) &&
            !request.url.includes('/auth/token-refresh')
          ) {
            this.jwtService.refreshToken({}).subscribe(
              res => {
                console.log('resp', res)
                this.tokenAuthService.setTokenStorage(res.access_token)
              },
              errorRefresh => {
                console.log('Error', errorRefresh)
                this.authenticationStateService.setAuthState(false)
              },
              () => {
                this.authenticationStateService.setAuthState(true)
              }
            )
          }
        }
        return throwError(error.error)
      }),
      finalize(() => false)
    )
  }
}
