import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import * as Sentry from '@sentry/angular-ivy'

if (environment.production) {
  enableProdMode()
}

Sentry.init({
  dsn: 'https://3d582825215cd45cf910de61f1d2f190@o4505742721613824.ingest.sentry.io/4505822635884544',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/clients.wanteez\.fr/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err))
