import { Injectable } from '@angular/core'
import { LoadingController } from '@ionic/angular'

@Injectable({
  providedIn: 'root',
})
export class IonLoaderService {
  loaderActive = false

  constructor(public loadingController: LoadingController) {}

  async simpleLoader(title) {
    await this.loadingController
      .create({
        message: title,
        cssClass: 'w-alert',
      })
      .then(response => {
        this.loaderActive = true
        response.present()
        response.onDidDismiss().then(_response => {
          this.loaderActive = false
          console.log('Loader dismissed', _response)
        })
      })
  }

  async dismissLoader() {
    setTimeout(async () => {
      await this.loadingController
        .dismiss()
        .then(async response => {
          if (!response) {
            this.loaderActive = false
            await this.dismissLoader()
          }
          console.log('Loader closed!', response)
        })
        .catch(err => {
          console.log('Error occured : ', err)
        })
    }, 200)
  }

  autoLoader(title, hideAfterSec = 4) {
    this.loadingController
      .create({
        cssClass: 'w-alert',
        message: title,
        duration: hideAfterSec * 1000,
      })
      .then(response => {
        response.present()
        this.loaderActive = true
        response.onDidDismiss().then(_response => {
          this.loaderActive = false
          console.log('Loader dismissed', _response)
        })
      })
  }

  customLoader(title, hideAfterSec = 4, classe = 'loader-css-class w-alert') {
    this.loadingController
      .create({
        message: title,
        duration: hideAfterSec * 1000,
        cssClass: classe,
        backdropDismiss: true,
      })
      .then(res => {
        this.loaderActive = true
        res.present()
      })
  }

  isActive() {
    return this.loaderActive
  }
}
