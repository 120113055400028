import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "days",
})
export class DaysPipe implements PipeTransform {
  transform(i: number): string {
    if (i.toString().length > 1) {
      i = new Date(i).getDay() + 1;
    }
    switch (i) {
      case 1:
        return "Lundi";
        break;
      case 2:
        return "Mardi";
        break;
      case 3:
        return "Mercredi";
        break;
      case 4:
        return "Jeudi";
        break;
      case 5:
        return "Vendredi";
        break;
      case 6:
        return "Samedi";
        break;
      case 7:
        return "Dimanche";
        break;
      default:
        break;
    }
  }
}

export function dayText(i: number): string {
  switch (i) {
    case 1:
      return "Lundi";
      break;
    case 2:
      return "Mardi";
      break;
    case 3:
      return "Mercredi";
      break;
    case 4:
      return "Jeudi";
      break;
    case 5:
      return "Vendredi";
      break;
    case 6:
      return "Samedi";
      break;
    case 7:
      return "Dimanche";
      break;
    default:
      break;
  }
}

export function shortDayText(i: number): string {
  switch (i) {
    case 1:
      return "Lun.";
      break;
    case 2:
      return "Mar.";
      break;
    case 3:
      return "Mer.";
      break;
    case 4:
      return "Jeu.";
      break;
    case 5:
      return "Ven.";
      break;
    case 6:
      return "Sam.";
      break;
    case 7:
      return "Dim.";
      break;
    default:
      break;
  }
}
