import { TitleCasePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";
import { shortDayText } from "../days/days.pipe";

@Pipe({
  name: "dateMission",
})
export class DateMissionPipe implements PipeTransform {
  constructor(private titlecase: TitleCasePipe) {}

  transform(datedebut, date_fin): unknown {
    return (
      this.titlecase.transform(moment.utc(datedebut).format("ddd")) +
      " " +
      moment.utc(datedebut).format("DD/MM/YYYY HH:mm") +
      " - " +
      moment.utc(date_fin).format("HH:mm")
    );
    let date = new Date(datedebut);
    let datefin = new Date(date_fin);
    const timezoneOffset1 = date.getTimezoneOffset() * 60000;
    const timezoneOffset2 = datefin.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() - timezoneOffset2);
    datefin = new Date(datefin.getTime() - timezoneOffset1);
    return `${shortDayText(
      date.getUTCDay() === 0 ? 7 : date.getUTCDay(),
    )} ${date.getUTCDate()}/${
      date.getUTCMonth() + 1
    }/${date.getUTCFullYear()} ${date.getUTCHours()}:${
      (date.getUTCMinutes() < 10 ? "0" : "") + date.getUTCMinutes()
    } - ${datefin.getUTCHours()}:${
      (datefin.getUTCMinutes() < 10 ? "0" : "") + datefin.getUTCMinutes()
    }`;
  }
}

export function dateMission(datedebut, date_fin): unknown {
  var titlecase = new TitleCasePipe();
  console.log("datedebut", datedebut);

  return (
    titlecase.transform(moment.utc(datedebut).format("ddd")) +
    " " +
    moment.utc(datedebut).format("DD/MM/YYYY HH:mm") +
    " - " +
    moment.utc(date_fin).format("HH:mm")
  );

  let date = new Date(datedebut);
  let datefin = new Date(date_fin);
  const timezoneOffset1 = date.getTimezoneOffset() * 60000;
  const timezoneOffset2 = datefin.getTimezoneOffset() * 60000;
  date = new Date(date.getTime() - timezoneOffset1);
  datefin = new Date(datefin.getTime() - timezoneOffset2);
  return `${shortDayText(
    date.getUTCDay() === 0 ? 7 : date.getUTCDay(),
  )} ${date.getUTCDate()}/${
    date.getUTCMonth() + 1
  }/${date.getUTCFullYear()} ${date.getUTCHours()}:${
    (date.getUTCMinutes() < 10 ? "0" : "") + date.getUTCMinutes()
  } - ${datefin.getUTCHours()}:${
    (datefin.getUTCMinutes() < 10 ? "0" : "") + datefin.getUTCMinutes()
  }`;
}

export function dateMissionEspace(datedebut, date_fin): unknown {
  let date = new Date(datedebut);
  let datefin = new Date(date_fin);
  const timezoneOffset1 = date.getTimezoneOffset() * 60000;
  const timezoneOffset2 = datefin.getTimezoneOffset() * 60000;
  date = new Date(date.getTime() - timezoneOffset1);
  datefin = new Date(datefin.getTime() - timezoneOffset2);
  return `${shortDayText(
    date.getUTCDay() === 0 ? 7 : date.getUTCDay(),
  )} ${date.getUTCDate()}/${
    date.getUTCMonth() + 1
  }/${date.getUTCFullYear()} ${date.getUTCHours()}:${
    (date.getUTCMinutes() < 10 ? "0" : "") + date.getUTCMinutes()
  } ${datefin.getUTCHours()}:${
    (datefin.getUTCMinutes() < 10 ? "0" : "") + datefin.getUTCMinutes()
  }`;
}

export function heureMission(datedebut, date_fin): unknown {
  return (
    moment(datedebut).format("HH:mm") + " - " + moment(date_fin).format("HH:mm")
  );
}

export function heureMissionBis(datedebut, date_fin): unknown {
  return (
    moment.utc(datedebut).format("HH:mm") +
    " - " +
    moment.utc(date_fin).format("HH:mm")
  );
}

export function jourMission(datedebut, date_fin): unknown {
  var titlecase = new TitleCasePipe();

  return (
    titlecase.transform(moment.utc(datedebut).format("ddd")) +
    " " +
    moment.utc(datedebut).format("DD/MM/YYYY")
  );
}
