import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http'
import { TokenAuthService } from 'src/app/services/token-auth/token-auth.service'
import { NEVER } from 'rxjs'
import { Router } from '@angular/router'
import { environment } from 'src/environments/environment'
import { Platform } from '@ionic/angular'

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
  constructor(
    private tokenAuthService: TokenAuthService,
    public router: Router,
    private platform: Platform
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const jwtHeaderToken = this.tokenAuthService.getJwtToken()
    console.log('req', req)
    if (
      !jwtHeaderToken &&
      !req.url.match(/auth\/signin-user/g) &&
      !req.url.match(/users\/reset-password/g) &&
      !req.url.match(/list-options/g) &&
      !req.url.match(/candidat\/step1/g) &&
      !req.url.match(/candidat\/update/g) &&
      !req.url.match(/candidat/g) &&
      !req.url.match(/uploads\/file/g) &&
      !req.url.match(/client/g)
    ) {
      this.router.navigate(['/auth/login'])
      return NEVER
    }
    req = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + jwtHeaderToken,
        'APPLI-VERSION': environment.appVersion,
        'APPLI-PLATFORM': this.platform.platforms().join(','),
      },
    })
    return next.handle(req)
  }
}
