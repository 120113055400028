import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import * as moment from 'moment'
import { Subject } from 'rxjs'
import { getIdsUndefined } from 'src/app/pipes/check-all-horraire/check-all-horraire.pipe'
import { dateMissionEspace } from 'src/app/pipes/date-mission/date-mission.pipe'
import { shortDayText } from 'src/app/pipes/days/days.pipe'
import { noSecond } from 'src/app/pipes/no-second/no-second.pipe'
import { DocumentType } from 'src/app/shared/data/DocumentType'
import { SharedService } from 'src/app/shared/shared.service'
import { ApiService } from '../api/api.service'
import { AuthenticationStateService } from '../authentication-state/authentication-state.service'
import { IonLoaderService } from '../ion-loader/ion-loader.service'
import { NotificationService } from '../notification/notification.service'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user: UserAgent
  public editUser: UserAgent
  public userRole: 'CANDIDAT' | 'CLIENT'
  public space: 'AGENT' | 'CLIENT'
  public isMutuelleExpired = true

  public isNoHistory = false

  public showAllElement = false
  public showSelectService = false
  docLoad = false
  dataSend = null
  loaderSave = false
  pageLoader = true
  client: any
  candidat: any
  missionId
  missionTitle
  besoins

  documents = {
    devis: [],
    factures: [],
    pieces_identites: [],
    ribs: [],
    sepa: [],
    feuillepaie: [],
    notefrais: [],
    arretmaladie: [],
    autres: [],
  }

  AgentChosen = false
  public isMutuelleMisison = false

  listAgents: any = []

  interventionsToCreate = []

  items = {
    ponctuel: [],
    recurrent: [],
    mutuelle: [],
  }
  SEGMENT: 'PONCTUEL' | 'RECURRENT' = 'PONCTUEL'
  private documentsRecuperesEvent = new Subject<any>()
  documentsRecuperesEvent$ = this.documentsRecuperesEvent.asObservable()

  constructor(
    public shared: SharedService,
    public router: Router,
    public api: ApiService,
    private auth: AuthenticationStateService,
    public notif: NotificationService,
    private ionloader: IonLoaderService
  ) {
    this.user = this.getUserInfos()
  }

  getUser(): void {}

  setEdit(): void {
    this.editUser = { ...this.user }
  }

  getUserById(id, type: 'candidat' | 'client'): void {
    console.log('TEST ICI1')
    if (type === 'candidat') {
      this.api.getCandidat(id).subscribe(
        (res: any) => {
          console.log('TEST ICI', res)
          if (res.success) {
            this.user = res.data
            this.candidat = res.data
            this.setUserInfos(res.data)
          }
          this.ionloader.dismissLoader()
        },
        () => {
          this.ionloader.dismissLoader()
        }
      )
    } else if (type === 'client') {
      this.api.getClient(id).subscribe(
        (res: any) => {
          if (res.success) {
            this.user = res.data
            this.client = res.data
            this.setUserInfos(res.data)
          }
          this.ionloader.dismissLoader()
        },
        () => {
          this.ionloader.dismissLoader()
        }
      )
    }
  }

  setUserInfos(userInfos) {
    if (userInfos) {
      userInfos.img = null
      try {
        localStorage.setItem('userInfos', JSON.stringify(userInfos))
      } catch (e) {
        console.error('Local Storage is full, Please empty data')
      }
    }
  }

  getUserInfos(): UserAgent {
    return JSON.parse(localStorage.getItem('userInfos')) || null
  }

  setSpace(space: 'AGENT' | 'CLIENT'): void {
    this.space = space
    this.router.navigateByUrl('profil')
  }

  getClient(id): void {
    this.api.getClient(id).subscribe(res => {
      this.client = res.data
      this.user = res.data
      this.besoins = this.getBesoins()
    })
  }

  getCandidat(id): any {
    return new Promise((resolve, reject) => {
      this.api.getCandidat(id).subscribe(
        res => {
          if (res.success) {
            this.besoins = this.getBesoins()
            this.candidat = res.data
            this.user = res.data
            this.ionloader.dismissLoader()
            resolve('success')
          } else {
            reject('ERROR')
          }
        },
        err => {
          reject('ERROR')
        }
      )
    })
  }

  checkDate(object) {
    const then = new Date(object)
    const now = new Date()

    if (then < now) {
      return false
    } else {
      return true
    }
  }

  getBesoins(): Promise<any> {
    let checkShowAll = true
    return new Promise((resolve, reject) => {
      let besoins = { ponctuel: [], recurrent: [], mutuelle: [] }
      try {
        this.showSelectService = true
        this.client?.missions.forEach(mission => {
          if (mission.mutuelle_id) {
            if (checkShowAll == true) {
              if (!this.checkDate(mission.date_fin)) {
                this.showAllElement = false
                console.log('expired mutuel')
              } else {
                this.showAllElement = true
                checkShowAll = false
                console.log('en cours mutuel')
              }
            }
            if (this.checkDate(mission.date_fin)) {
              besoins.mutuelle.push({
                titre: this.getTitreBesoinSkill(mission.client_besoin_skill_id)
                  .skill,
                icon: this.getTitreBesoinSkill(mission.client_besoin_skill_id)
                  .icon,
                lists: this.getHorraires(mission.devis_id, mission, 'ponctuel'),
                mission_id: mission.id,
                nb_heures_total: mission.nb_heures_total,
                nb_heures_consommees: mission.nb_heures_consommees,
                nb_minutes_a_planifier: mission.nb_minutes_a_planifier,
                date_debut: mission.date_debut,
                date_fin: mission.date_fin,
                interventions: mission.interventions,
                mutuelle_name: mission.mutuelle?.nom,
                reference: mission.reference,
                is_mission_mutuelle: true,
              })
              if (mission.nb_heures_consommees < mission.nb_heures_total) {
                this.showSelectService = false
              }
            }
          } else {
            this.showAllElement = true
            if (mission.is_ponctuel && mission.is_ponctuel !== 0) {
              besoins.ponctuel.push({
                titre: this.getTitreBesoinSkill(mission.client_besoin_skill_id)
                  .skill,
                icon: this.getTitreBesoinSkill(mission.client_besoin_skill_id)
                  .icon,
                lists: this.getHorraires(mission.devis_id, mission, 'ponctuel'),
                mission_id: mission.id,
                date_debut: mission.date_debut,
                date_fin: mission.date_fin,
                interventions: mission.interventions,
                notification: getIdsUndefined(mission, 'ponctuel'),
              })
            } else {
              mission.interventions = mission.interventions.slice(0, 4)
              besoins.recurrent.push({
                titre: this.getTitreBesoinSkill(mission.client_besoin_skill_id)
                  .skill,
                icon: this.getTitreBesoinSkill(mission.client_besoin_skill_id)
                  .icon,
                lists: this.getHorraires(
                  mission.devis_id,
                  mission.date_debut,
                  'recurrent'
                ),
                mission_id: mission.id,
                date_debut: mission.date_debut,
                date_fin: mission.date_fin,
                interventions: mission.interventions,
                horaires_recurents: mission.horaires_recurents
                  ? mission.horaires_recurents
                  : [],
                notification: getIdsUndefined(mission),
              })
            }
          }
        })

        if (besoins.mutuelle.length == 0) {
          this.showAllElement = true
        }
        console.log('besoins.recurrent', besoins.recurrent)
      } catch (err) {
        reject(err)
      }
      resolve(besoins)
    })
  }

  getHorraires(devisId, date, type: 'ponctuel' | 'recurrent'): any[] {
    let horraires = []
    if (type === 'ponctuel') {
      const mission = date /* mission pour ponctuel */
      mission.interventions.forEach(inter => {
        const dd = new Date(inter.date_debut)
        const df = new Date(inter.date_fin)
        const heure =
          dd.getUTCHours() +
          ':' +
          (dd.getUTCMinutes() < 10 ? 0 : '') +
          dd.getUTCMinutes() +
          ' - ' +
          df.getUTCHours() +
          ':' +
          (df.getUTCMinutes() < 10 ? 0 : '') +
          df.getUTCMinutes()

        if (horraires.length > 0) {
          const ind = this.dayExist(horraires, dd.getTime())
          if (ind) {
            horraires[ind].times.push(heure)
          } else {
            horraires.push({
              day: dd.getTime(),
              times: [heure],
            })
          }
        } else {
          horraires.push({
            day: dd.getTime(),
            times: [heure],
          })
        }
      })
      horraires.reverse()
    } else {
      this.client.devis.forEach(dev => {
        if (dev.id === devisId) {
          dev.devis_details.forEach(dd => {
            dd.devis_detail_horaires.forEach(dh => {
              if (horraires.length > 0) {
                const ind = this.dayExist(horraires, dh.day)
                if (ind) {
                  horraires[ind].times.push(dh.time_debut + ' - ' + dh.time_fin)
                  horraires[ind].horaires.push({
                    start_time: dh.time_debut,
                    end_time: dh.time_fin,
                  })
                } else {
                  horraires.push({
                    day: dh.day,
                    times: [dh.time_debut + ' - ' + dh.time_fin],
                    horaires: [
                      {
                        start_time: dh.time_debut,
                        end_time: dh.time_fin,
                      },
                    ],
                  })
                }
              } else {
                horraires.push({
                  day: dh.day,
                  times: [dh.time_debut + ' - ' + dh.time_fin],
                  horaires: [
                    {
                      start_time: dh.time_debut,
                      end_time: dh.time_fin,
                    },
                  ],
                })
              }
            })
          })
          horraires.reverse()
        }
      })
    }
    return horraires
  }

  dayExist(arr: any[], day: number): any {
    let index = null
    arr.forEach((ar: any, i) => {
      if (ar.day === day) {
        index = i
        return i
      }
    })
    return index
  }

  getTitreBesoinSkill(id): { skill: string; icon: string } {
    let str = { skill: '', icon: '' }
    switch (id) {
      case 1:
        str.skill = 'Entretien à domicile / repassage'
        str.icon = 'entretien-black.png'
        break
      case 2:
        str.skill = 'Accompagnement personnes Dépendantes / Auxiliaire de Vie'
        str.icon = 'auxiliaire-black.png'
        break
      case 3:
        str.skill = 'Baby Sitting'
        str.icon = 'baby-black.png'
        break
      case 4:
        str.skill = "Garde d'enfants"
        str.icon = 'garde-black.png'
        break
      case 5:
        str.skill = 'Gouvernante'
        str.icon = 'entretien-black.png'
        break
      default:
        break
    }
    return str
  }

  setHorairesSouhaitees(mission): any {
    let hor = []
    console.log('mission 448', mission)
    if (
      (mission.is_ponctuel && mission.is_ponctuel != 0) ||
      mission.is_ponctuel == 1
    ) {
      const listIds = getIdsUndefined(mission, 'ponctuel')
      mission.interventions.forEach(inter => {
        if (!mission.is_ponctuel || mission.is_ponctuel === 0) {
        } else {
          hor.push({
            start_date: moment
              .utc(inter.date_debut)
              .format('YYYY-MM-DD HH:mm:ss'),
            end_date: moment.utc(inter.date_fin).format('YYYY-MM-DD HH:mm:ss'),
            value: dateMissionEspace(inter.date_debut, inter.date_fin),
          })
        }
      })
    } else {
      const listIds = getIdsUndefined(mission)
      mission.horaires_recurents.forEach(h => {
        console.log('ici')
        console.log(h)
        if (h.agent == null) {
          console.log('icilaa')
          console.log(h)
          hor.push({
            mission_id: h.mission_id,
            day: h.day,
            start_date: h.start_time,
            end_date: h.end_time,
            value:
              shortDayText(h.day) +
              ' ' +
              noSecond(h.start_time + ' - ' + h.end_time),
          })
        }
      })
    }
    console.log(hor)
    return hor
  }

  initClient(user, lazy = false): any {
    this.ionloader.simpleLoader('Chargement de votre compte')
    if (!lazy) {
      this.pageLoader = true
    }
    user = typeof user === 'string' ? JSON.parse(user) : user
    console.log('init', user)
    this.api.getClient(user.client_id).subscribe(
      async res => {
        this.ionloader.dismissLoader()

        console.log('RESCLIENT', res)
        if (res) {
          this.user = res.data
          this.client = res.data
          this.getBesoins().then(
            async bes => {
              if (bes) {
                this.isNoHistory = true
              }
              this.besoins = bes
              this.besoins.ponctuel.forEach(b =>
                this.items.ponctuel.push({ expanded: false })
              )
              if (this.items.ponctuel.length > 0) {
                this.items.ponctuel[0].expanded = true
              }
              this.besoins.recurrent.forEach(b =>
                this.items.recurrent.push({ expanded: false })
              )
              if (this.items.recurrent.length > 0) {
                this.items.recurrent[0].expanded = true
              }
              if (
                this.besoins.ponctuel.length === 0 &&
                this.besoins.recurrent.length > 0
              ) {
                this.SEGMENT = 'RECURRENT'
              }
              this.besoins.mutuelle.forEach(b =>
                this.items.mutuelle.push({ expanded: false })
              )
              if (this.items.mutuelle.length > 0) {
                this.items.mutuelle[0].expanded = true
              }
              if (!lazy) {
                this.ionloader.dismissLoader()
              }
              await this.notif.setBesoin(this.besoins)
              await this.notif.setTotal()
            },
            () => {
              if (!lazy) {
                this.ionloader.dismissLoader()
              }
            }
          )
        } else {
          if (!lazy) {
            this.ionloader.dismissLoader()
          }
        }
      },
      () => {
        if (!lazy) {
          this.ionloader.dismissLoader()
        }
      }
    )
  }

  getClientDocs() {
    this.docLoad = true
    const user =
      typeof this.auth.userValue === 'string'
        ? JSON.parse(this.auth.userValue)
        : this.auth.userValue
    this.api.getDocumentsClients(user.client_id).subscribe(
      res => {
        if (res?.success) {
          this.setDocuments(res.documents)
          this.documentsRecuperes()
        }
        this.ionloader.dismissLoader()
      },
      () => {
        this.ionloader.dismissLoader()
      }
    )
  }

  async setDocuments(docs: any[]) {
    console.log('Get Clients Documents', docs)
    this.documents = {
      devis: [],
      factures: [],
      pieces_identites: [],
      ribs: [],
      sepa: [],
      feuillepaie: [],
      notefrais: [],
      arretmaladie: [],
      autres: [],
    }
    await docs.forEach(doc => {
      switch (+doc.document_type.id) {
        case DocumentType.FACTURE:
          this.documents.factures.push(doc)
          break
        case DocumentType.RIB:
          this.documents.ribs.push(doc)
          break
        case DocumentType.IDCARD:
          this.documents.pieces_identites.push(doc)
          break
        case DocumentType.IDCARD:
          this.documents.pieces_identites.push(doc)
          break
        case DocumentType.DEVIS_CLIENT:
          this.documents.devis.push(doc)
          break
        case DocumentType.SEPA:
          this.documents.sepa.push(doc)
          break
        case DocumentType.FEUILLEPAIE:
          this.documents.feuillepaie.push(doc)
          break
        case DocumentType.NoteDeFrais:
          this.documents.notefrais.push(doc)
          break
        case DocumentType.ARRETMALADIE:
          this.documents.arretmaladie.push(doc)
          break
        default:
          this.documents.autres.push(doc)
          break
      }
    })
    console.log('Autres documents', this.documents.autres)
  }

  getCandidatDocs() {
    this.docLoad = true
    const user =
      typeof this.auth.userValue === 'string'
        ? JSON.parse(this.auth.userValue)
        : this.auth.userValue
    this.api.getDocumentsCandidats(user.candidat_id).subscribe(
      res => {
        if (res?.success) {
          this.setDocuments(res.documents)
          this.documentsRecuperes()
        }
        this.ionloader.dismissLoader()
      },
      () => {
        this.ionloader.dismissLoader()
      }
    )
  }

  documentsRecuperes() {
    this.documentsRecuperesEvent.next()
  }
}

export interface UserAgent {
  nom: string
  prenom: string
  mobile: any
  id_card: any
  telephone: string
  adresse: string
  email: string
  lieu_travail: string
  birthplace?: string
  description: string
  video: null
  postes: any[]
  contrat: string
  transports: number
  disponibilites: any[]
  cv?: string
  pass_sanitaire?: string
  lat?: any
  lng?: any
  civilite?: any
  nationalite?: any
  ville?: any
  codep?: any
  country?: any
  phone?: any
  birthday?: any
  birthcity?: any
  photo?: any
  pass?: any
  cv_filename?: any
  age: any
  bus_mission_annonce_id: number
  candidat_commentaires: any[]
  candidat_postes: any[]
  commentaires: any
  commentairesTooltip: string
  created_at: any
  date_entree_service: any
  date_naissance: any
  disponibilite: any
  disponibilite_date: any
  duree_travail: any
  experience: any
  fullname: string
  historique_actions: any[]
  id: number
  img: any
  lieu_naissance: any
  num_permis_travail: null
  num_securite_social: null
  origine: any
  origine_id: number
  pass_sanitaire_filename: any
  perimetre_intervention_km: number
  photo_profil_filename: string
  session_id: string
  statut: { id: number; label: string }
  statut_id: number
  tel: any
  transport_type: any
  typeTransportIcon: any
  typeTransportLabel: any
  type_transport: any
  updated_at: any
  updated_by: any
  updated_by_user: any
  video_presentation_filename: any
  ville_naissance: any
  'poste[]': any[]
  events_dispos?: any[]
  demission_en_cours: any
}
